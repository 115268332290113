<template> 
  <div class="admin-log"> 
    <v-tabs v-model="tab">
      <v-tab>Messages</v-tab>
      <v-tab>Reports</v-tab>
      <v-tab>Blocked Users</v-tab>
      <v-tab>Muted Users</v-tab>
    </v-tabs>   
    
      <v-tabs-items v-model="tab" class="content">
        <v-tab-item>
          <div v-for="(message, i) in messages" :key="`admin-message-${i}`" class="message">
            <div class="pod">
              <span>Pod #{{ message.pod }}</span>
              <v-btn @click="kick(message)" color="red darken-4" class="action-btn">
                kick user
              </v-btn>
              <v-btn
                @click="mute(message)"
                color="blue-grey darken-3"
                class="action-btn"
                v-text="message.disabled ? 'unmute' : 'mute'"
              ></v-btn>
            </div>
            <div class="uid"> Username: {{ message.username }} (UID={{ message.uid }}) </div>
            <div class="message-content">Message: <span class="message-text">{{ message.content }}</span></div>
          </div>
        </v-tab-item>

        <v-tab-item>
          
          <div v-for="(message, i) in reports" :key="`report-${i}`" class="message">
            <div class="pod">
              <span>Pod #{{ message.pod }}</span>
              <v-btn @click="kick(message)" color="red darken-4" class="action-btn">
                kick user
              </v-btn>
              <v-btn
                @click="mute(message)"
                color="blue-grey darken-3"
                class="action-btn"
              >
                {{ message.disabled ? 'unmute' : 'mute' }}
              </v-btn>
            </div>
            <div class="uid">User <span class="message-text">{{ message.username }}</span> was reported</div>
          </div>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            :headers="blockedUsersHeaders"
            :items="blockedUsers"
          >
            <template v-slot:item.userCreateDate="{ item }">
              {{ new Date(item.userCreateDate).toLocaleDateString() }}
            </template>
            
            <template v-slot:item.action="{ item }">
              <v-btn @click="unblock(item)">Unblock</v-btn> 
            </template>
          </v-data-table>
        </v-tab-item>
        
        <v-tab-item>
          <!--<v-select v-model="selectedConcert" :items="concerts" @change="getMutedUsers" solo class="concert-field">
          </v-select>-->

          <v-data-table
            :headers="mutedUsersHeaders"
            :items="mutedUsers"
          >
            <template v-slot:item.userCreateDate="{ item }">
              {{ new Date(item.userCreateDate).toLocaleDateString() }}
            </template>
            
            <template v-slot:item.action="{ item }">
              <v-btn @click="unmute(item)">Unmute</v-btn> 
            </template>
          </v-data-table>
        </v-tab-item>

      </v-tabs-items>
  </div>
</template>

<script>
import AgoraRTM from "agora-rtm-sdk";
import { AppId } from "@/services/agora";
import { getRTMToken } from "@/services/chat";

import loader from "@/config.loader";
import request from "@/plugins/request";

import awsAuth from '@/cognito/aws-auth';

let channel;

const client = AgoraRTM.createInstance(AppId);

export default {
  data() {
    return {
      tab: null,

      messages: [],
      reports: [],

      concerts: [],
      selectedConcert: 9987,
      
      currentUser: awsAuth.getUserTokens(),

      mutedUsers: [],
      mutedUsersHeaders: [
        { text: 'Username', value: 'username', align:'center' },
        // { text: 'Joined', value: 'userCreateDate', align:'center' },
        { text: 'Action', value: 'action', align:'center' },
      ],
      mutedUsersOffset: 0,

      blockedUsers: [],
      blockedUsersHeaders: [
        { text: 'Username', value: 'username', align:'center' },
        { text: 'Joined', value: 'userCreateDate', align:'center' },
        { text: 'Enabled', value: 'enabled', align:'center' },
        { text: 'Action', value: 'action', align:'center' },
      ],
      blockedUsersPagination: null,

      sessionToken: null
    };
  },
  mounted() {
    this.setup();
    this.getConcerts();
    this.repeatRequest();
  },
  methods: {
    async repeatRequest(){      
      await this.getBlockedUsers();
      await this.getMutedUsers();
      setTimeout(this.repeatRequest, 3000);
    },
    async getConcerts() {
      const url = `/concert/api/v1/Manager/GetConcertList`;
      const result = await request({ method: 'GET', url });

      console.log(result);

      this.concerts = result.data;
    },
    async unblock(item) {
      const url = `/concert/api/v1/Manager/EnableUser`;
      const body = {
        username: item.username,
        userPoolId: loader.getConfigValue('VUE_COGNITO_USER_POOL_ID')
      };

      await request({ method: 'POST', url, body });
      
      this.blockedUsers = this.blockedUsers
        .filter(user => user.username !== item.username);
    },
    async unmute(item) {
      console.log(item)
      const url = `/concert/api/v1/Manager/UnmuteUser?userId=${item.userId}&concertId=${this.selectedConcert}`;

      await request({ method: 'POST', url });

      this.mutedUsers = this.mutedUsers
        .filter(user => user.userId !== item.userId);
    },
    async getMutedUsers() {
      const url = `/concert/api/v1/Manager/GetMutedUsers?limit=20&offset=${this.mutedUsersOffset}&concertId=${this.selectedConcert}`;
      const res = await request({ url, method: 'GET' })

      this.mutedUsers = [...res.data.users.map(u => ({ username: u.username, userId: u.userId }))];
      this.mutedUsersOffset = 0;
    },
    async getBlockedUsers() {
      const pool = loader.getConfigValue('VUE_COGNITO_USER_POOL_ID');
      const next = this.blockedUsersPagination 
        ? '&paginationToken=' + this.blockedUsersPagination
        : '';

      const url = `/concert/api/v1/Manager/GetDisabledUsers?limit=20&userPoolId=${pool}${next}`;
      const res = await request({ url, method: 'GET'  })

      this.blockedUsers = [...res.data.users];
      this.blockedUsersPagination = res.data.paginationToken;
    },
    async setup() {
      const uid = Math.floor(Math.random() * 10000000000).toString();//this.currentUser.userId;
      const token = await getRTMToken(uid);

      await client.login({ uid, token });

      channel = client.createChannel('admin-global-chat');

      await channel.join();
      
      channel.on('ChannelMessage', async (message) => {
        const messageData = JSON.parse(message.text);
        const baseUrl = loader.getConfigValue("VUE_AGORA_API_URL");
        const username = await fetch(
          `${baseUrl}/username?uid=${messageData.uid}&pod=${messageData.pod}`
        )
          .then((res) => res.json())
          .then((res) => res.username)
          .catch((error) => console.log(error));

        if (messageData.type === "REPORT") {
          this.reports.push({ ...messageData, username });
        } else {
          this.messages.push({ ...messageData, username });
        }
      });
    },
    kick(data) {
      let body = {
        username: data.username,
        userId: data.userId,
        message: 'closeUserSession',
        userPoolId: loader.getConfigValue('VUE_COGNITO_USER_POOL_ID')
      };
      request({ method: 'POST', url: `/concert/api/v1/Manager/DisableUser`, body: JSON.stringify(body)},  this.$store)
        .then((result)=>{
          console.log(result)
        })
        .catch((error)=>{
          console.error(`ERROR ${error}`)
        })   
    },
    mute(data) {
      const disabled = data.disabled;
      data.disabled = !data.disabled;

      const update = message => message.userId === data.userId
        ? { ...message, disabled: data.disabled }
        : message;

      this.messages =  this.messages.map(update);
      this.reports =  this.reports.map(update);

      console.log(data, data.concertId);

      request({ method: 'POST', url: `/concert/api/v1/Manager/${disabled ? 'Unmute' : 'Mute'}User?userId=${data.userId}&concertId=${data.concertId}`}, this.$store)
        .then((result)=>{
          console.log(result.status)
        })
        .catch((error)=>{
          console.error(`ERROR ${error}`)
        })
    },
  },
};
</script>

<style scoped lang="scss">
.admin-log { 
  margin: 1rem;

  border: 2px solid rgba(194, 194, 194, 0.3);
  border-radius: 0.2rem;

  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: scroll;
  
  max-height: 70vh;

}

.concert-field {
  max-width: 250px;
}

.content {
  padding: 1rem;
}

.message-content {
  padding: 0 0 1rem 0;
}
.message-text {
  color: #FF6E40;
}

.admin-log > .message {
  padding: 1rem;
  margin: 1rem;

  & > .pod {
    font-size: 18px;
    font-weight: bold;
  }

  & > .uid {
    color: rgb(209, 137, 28);
  }
}

.action-btn {
  margin: 0.2rem;
}
</style>